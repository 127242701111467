import { Box, Divider, Flex } from "@chakra-ui/react";
import {
  Deal,
  LoanPassPricingAggregationIntergrationFields,
  LoanpassEventObject
} from "@elphi/types";
import { BaseSchemaDeal } from "@elphi/types/entities/base";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get } from "lodash";
import { EMPTY, NOT_AVAILABLE } from "../../../../../constants/common";
import { getSpecs } from "../../../../../forms/schemas/factories/specsFactory";
import { AggregationsIntegrations } from "../../../../../forms/schemas/integrations/types/integrations.types";
import { PricingDataComparison } from "./CompareSection";
type InMappingModalBodyProps = {
  messageType: LoanpassEventObject["message"];
  mappedDeal: Partial<BaseSchemaDeal>;
  selectedDeal: Deal;
};

type PricingPaths = DotNestedKeys<
  AggregationsIntegrations<LoanPassPricingAggregationIntergrationFields>
>;
const investorNamePath: PricingPaths =
  "aggregations.Integrations.Pricing.Loanpass.investorName.thirdParty";
const stipulationsPath: PricingPaths =
  "aggregations.Integrations.Pricing.Loanpass.stipulations.thirdParty";

const headerPaths: PricingPaths[] = [
  "aggregations.Integrations.Pricing.Loanpass.adjustedPrice.thirdParty",
  "aggregations.Integrations.Pricing.Loanpass.adjustedRate.thirdParty",
  "aggregations.Integrations.Pricing.Loanpass.lockPeriod.thirdParty",
  "aggregations.Integrations.Pricing.Loanpass.totalLLPA.thirdParty"
];

type HeaderSectionsProps = {
  mappedDeal: Partial<BaseSchemaDeal>;
  dealSpec: any;
};
const Header = (props: HeaderSectionsProps) => {
  const subHeader = get(props.mappedDeal, investorNamePath) || NOT_AVAILABLE;
  return (
    <Flex gap="12px" direction="column">
      <Box fontWeight={"600"}>{subHeader}</Box>
      <Flex justifyContent={"space-between"}>
        {headerPaths.map((path) => {
          const value = get(props.mappedDeal, path) || NOT_AVAILABLE;
          const fieldSpec = get(props.dealSpec, path);
          const label = fieldSpec.label || NOT_AVAILABLE;
          return (
            <Flex direction="column" key={path}>
              <Box>{value}</Box>
              <Box color={"gray.600"}>{label}</Box>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

const Stipulations = (props: HeaderSectionsProps) => {
  const stipulations = get(props.mappedDeal, stipulationsPath) || EMPTY;
  const stipulationsArray = stipulations.split(";");
  const fieldSpec = get(props.dealSpec, stipulationsPath);
  const label = fieldSpec.label || NOT_AVAILABLE;

  return (
    <Flex gap="12px" direction="column">
      <Box fontWeight={"600"}>{label}</Box>
      <Flex gap={4} direction="column">
        {stipulations
          ? stipulationsArray.map((stipulation) => (
              <Box key={stipulation}>{stipulation}</Box>
            ))
          : NOT_AVAILABLE}
      </Flex>
      <Divider borderColor={"gray.400"} />
    </Flex>
  );
};

export const InMappingModalBody = (props: InMappingModalBodyProps) => {
  const spec = getSpecs();
  const dealSpec = spec.deal.entitySpecs;
  return (
    <>
      <Flex
        direction="column"
        gap="12px"
        border="1px solid"
        borderColor={"gray.200"}
        p={8}
      >
        <Header mappedDeal={props.mappedDeal} dealSpec={dealSpec} />
        <Divider borderColor={"gray.400"} />
        <Stipulations mappedDeal={props.mappedDeal} dealSpec={dealSpec} />
        <PricingDataComparison
          mappedDeal={props.mappedDeal}
          selectedDeal={props.selectedDeal}
          dealSpec={dealSpec}
        />
      </Flex>

      <Box fontWeight={"500"} mb={4} mt={2}>
        The selected values will be transferred to the LOS for reference and
        will not override existing values.
      </Box>
      <Box fontWeight={"500"} color="red">
        Please note, the table does not include all data from the LOS or
        product.
      </Box>
    </>
  );
};
