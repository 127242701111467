import { Box, Heading, useDimensions } from "@chakra-ui/react";
import { Deal, Property } from "@elphi/types";
import lodash from "lodash";
import { useEffect, useRef, useState } from "react";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";
import { usePropertyHooks } from "../../hooks/property.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";

import LeasingTable from "../property/LeasingTable";
import { PropertyScrollableSections } from "../property/PropertyScrollableSections";
import SectionsAfterLeasingTable from "../property/SectionsAfterLeasingTable";
import SectionsAfterTables from "../property/SectionsAfterTables";
import SectionsBeforeTables from "../property/SectionsBeforeTables";
import ScrollableSections from "../ScrollableSections";
import DealPropertySearchFormWidget from "./DealPropertySearchFormWidget";
import PropertyAssessmentsTable from "./PropertyAssessmentsTable";
import { hideRental30 } from "./sections/sections.utils";

import { TabFormProps } from "./TabForm";
import TaxAssessmentsTable from "./TaxAssessmentsTable";

export type PropertyTabFormProps = Pick<
  TabFormProps,
  "dealId" | "onChange" | "isLoading"
> & {
  dealState: Deal;
  navigationPath?: NavigationPath;
};
export const PropertyTabFormContainer = (
  props: PropertyTabFormProps & { snapshotId?: string }
) => {
  return props.snapshotId ? (
    <PropertyTabFormSnapshotContainer
      {...props}
      snapshotId={props.snapshotId!}
    />
  ) : (
    <PropertyTabFormLiveStateContainer {...props} />
  );
};
export const PropertyTabFormSnapshotContainer = (
  props: PropertyTabFormProps & { snapshotId: string }
) => {
  const { snapshotDataState } = useSnapshotHooks();
  const { propertyState, setSelectedProperty } = usePropertyHooks();
  const snapshotData = snapshotDataState({
    snapshotId: props.snapshotId
  });
  return (
    <PropertyTabForm
      {...props}
      selectedProperty={
        (propertyState.selectedId &&
          snapshotData.propertyState?.entities[propertyState.selectedId]) ||
        undefined
      }
      snapshotId={props.snapshotId}
      setSelectedProperty={setSelectedProperty}
    />
  );
};
export const PropertyTabFormLiveStateContainer = (
  props: PropertyTabFormProps
) => {
  const { selectedProperty, setSelectedProperty } = usePropertyHooks();
  return (
    <PropertyTabForm
      {...props}
      selectedProperty={selectedProperty}
      setSelectedProperty={setSelectedProperty}
    />
  );
};
const PropertyTabForm = (
  props: PropertyTabFormProps & {
    selectedProperty: Property | undefined;
    snapshotId?: string;
    setSelectedProperty: ReturnType<
      typeof usePropertyHooks
    >["setSelectedProperty"];
    isDisabled?: boolean;
  }
) => {
  const [propsSectionMaxHeight, setPropsSectionMaxHeight] = useState<number>();
  const { selectedProperty, setSelectedProperty, navigationPath } = props;
  const { propertyState } = usePropertyHooks();
  const elementRef = useRef(null);
  const dimensions = useDimensions(elementRef);
  const HEADER_HEIGHT = "40px";
  const SCROLL_SECTION_OFFSET = "40px";
  const SINGLE_PROPERTY_HEIGHT = 250;
  const PROPERTY_HEIGHT_MULTIPLIER = 150;

  useEffect(() => {
    const propertyCount = propertyState?.ids?.length;

    if (propertyCount > 0) {
      const calculatedHeight = propertyCount * PROPERTY_HEIGHT_MULTIPLIER;
      const maxHeight =
        propertyCount === 1 ? SINGLE_PROPERTY_HEIGHT : calculatedHeight;

      setPropsSectionMaxHeight(maxHeight);
    }
    return () => {
      setPropsSectionMaxHeight(undefined);
    };
  }, [propertyState.ids]);

  return (
    <Box h="100%" w="100%">
      <Box w="100%" minH={HEADER_HEIGHT}>
        <DealPropertySearchFormWidget
          dealId={props.dealId}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          snapshotId={props.snapshotId}
        />
      </Box>
      <Box
        h={`calc(100% - ${SCROLL_SECTION_OFFSET})`}
        overflow="scroll"
        scrollBehavior="smooth"
      >
        {!!navigationPath && selectedProperty && (
          <Box>
            <ExpandAllButton navigationPath={navigationPath} />
          </Box>
        )}
        <PropertyScrollableSections
          elementRef={elementRef}
          maxHeight={propsSectionMaxHeight}
          isDisabled={!!props.snapshotId}
          selectedProperty={selectedProperty}
          navigationPath={navigationPath}
          dealId={props.dealId}
          dimensions={dimensions}
          snapshotId={props.snapshotId!}
        />
        {!lodash.isEmpty(selectedProperty) && (
          <Box h="100%">
            <Box bgColor="white">
              <SectionsBeforeTables
                dealState={props.dealState}
                selectedProperty={selectedProperty}
                snapshotId={props.snapshotId}
                navigationPath={navigationPath}
              />
            </Box>
            <Box bgColor="white">
              <ScrollableSections
                customKey="leasingTable"
                sections={[
                  {
                    index: 0,
                    header: <Heading>Leasing Table</Heading>,
                    body: (
                      <LeasingTable
                        selectedProperty={selectedProperty}
                        snapshotId={props.snapshotId}
                      />
                    ),
                    isHidden: hideRental30(props.dealState)
                  }
                ]}
                navigationPath={navigationPath}
              />
            </Box>
            <Box bgColor="white">
              <SectionsAfterLeasingTable
                dealState={props.dealState}
                selectedProperty={selectedProperty}
                snapshotId={props.snapshotId}
                navigationPath={navigationPath}
              />
            </Box>
            <Box bgColor="white">
              <ScrollableSections
                customKey={"taxAssessmentsImpounds"}
                sections={[
                  {
                    index: 0,
                    header: <Heading>Tax Assessments and Impounds</Heading>,
                    body: (
                      <Box>
                        <TaxAssessmentsTable
                          selectedProperty={selectedProperty}
                          snapshotId={props.snapshotId}
                        />
                      </Box>
                    ),
                    isHidden: hideRental30(props.dealState)
                  }
                ]}
                navigationPath={navigationPath}
              />
            </Box>
            <Box bgColor="white">
              <ScrollableSections
                customKey={"propertyAssessmentsImpounds"}
                sections={[
                  {
                    index: 0,
                    header: (
                      <Heading>Property Assessments and Impounds</Heading>
                    ),
                    body: (
                      <PropertyAssessmentsTable
                        selectedProperty={selectedProperty}
                        snapshotId={props.snapshotId}
                      />
                    ),
                    isHidden: hideRental30(props.dealState)
                  }
                ]}
                navigationPath={navigationPath}
              />
            </Box>
            <Box bgColor="white">
              <SectionsAfterTables
                dealState={props.dealState}
                selectedProperty={selectedProperty}
                snapshotId={props.snapshotId}
                navigationPath={navigationPath}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PropertyTabForm;
