import { ElphiDomain } from "../../../utils/envUtils";
import { DomainOwner } from "../types/dynamicSchema.types";

// TODO: env var
export const getDomainOwner = () => {
  const domain = window.location.hostname;
  switch (domain) {
    case ElphiDomain.EdomonTest:
      return DomainOwner.Elphi;
    case ElphiDomain.LimaPoc:
    case ElphiDomain.LimaProd:
    case ElphiDomain.LimaTest:
    case ElphiDomain.ElamiaTest:
      return DomainOwner.Lima;
    case ElphiDomain.MofinTest:
    case ElphiDomain.MofinProd:
    case ElphiDomain.Localhost:
      return DomainOwner.Mofin;
    default:
      return DomainOwner.Elphi;
  }
};
