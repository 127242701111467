import { Deal, FieldType } from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../../../../../constants/common";
import { useDealFormHandler } from "../../../../../../hooks/dealform.hooks";
import { PipelineRecordsSearchSearch } from "../../../pricingEngine/PipelineRecordsSearch";
import { useLoanpassHooks } from "../../../pricingEngine/loanpass.hooks";
import {
  LoanpassCollapseSection,
  LoanpassSection,
  LoanpassSectionsProps
} from "../LoanPassSections";

export const PipelineRecordInformation = (props: LoanpassSectionsProps) => {
  const { sectionBuilders } = props;
  const { selectPipelineRecord, selectedPipelineRecord } = useLoanpassHooks();
  const { dealOnChange, dealState } = useDealFormHandler(props.dealId);

  const section = sectionBuilders?.({
    state: dealState,
    onChange: dealOnChange
  });

  return (
    <LoanpassCollapseSection
      shouldShow={props.shouldShow}
      title="Pipeline Record Information"
      customBody={
        <LoanpassSection
          customKey="loanpass-pipeline-record-sections"
          section={section}
          onChange={dealOnChange}
          searchComponent={
            <PipelineRecordsSearchSearch
              fieldType={FieldType.SingleSelect}
              currentValue={selectedPipelineRecord?.id || EMPTY}
              onSelect={selectPipelineRecord}
            />
          }
        />
      }
    />
  );
};

export const LoanInformation = (
  props: LoanpassSectionsProps<{ state: Deal }>
) => {
  const { dealOnChange, dealState, selectedDeal } = useDealFormHandler(
    props.dealId
  );

  const loanIdentifier = selectedDeal?.LoanIdentifier || NOT_AVAILABLE;
  const section = props.sectionBuilders?.({
    state: dealState,
    onChange: dealOnChange
  });

  return (
    <LoanpassCollapseSection
      shouldShow={props.shouldShow}
      title={`Loan Information - ${loanIdentifier}`}
      customBody={
        <LoanpassSection
          customKey="loanpass-deal-sections"
          section={section}
          onChange={dealOnChange}
        />
      }
    />
  );
};
