import { TObject, TProperties, TSchema, Type } from "@sinclair/typebox";
import {
  Party,
  PartyType,
  TaxpayerIdentifierType,
  getPartyTemplate
} from "../../entities";
import {
  mofinEntityType,
  mofinOperationsDepartment
} from "../../entities/mofin/party/mofinParty.enumeration";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import { BasePaginationRequest } from "../shared.typebox";
import {
  AggregationFieldSchema,
  DateField,
  Deprecated,
  EnumField,
  LiteralField,
  MoneyAmountFieldString,
  StringField,
  UnionField
} from "../utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "../utils/fieldMeta.utils";

export const PartyTypeSchema = Type.Union([
  Type.Literal(PartyType.Entity),
  Type.Literal(PartyType.Individual)
]);

export const TaxpayerIdentifierTypeSchema = Type.Union([
  Type.Literal(TaxpayerIdentifierType.EIN),
  Type.Union([
    Type.Literal(TaxpayerIdentifierType.SSN),
    Type.Literal(TaxpayerIdentifierType.TIN)
  ])
]);

export const BasePartyAggregationsSchema = Type.Object({
  BackgroundReportExpirationDate: AggregationFieldSchema(DateField)
});

export const IndividualPartyAggregationsSchema = Type.Object({
  TotalStatementQualifyingBalance: AggregationFieldSchema(
    MoneyAmountFieldString
  ),
  LOCExpirationDate: AggregationFieldSchema(DateField)
});
export const EntityPartyAggregationsSchema = Type.Object({
  TotalStatementQualifyingBalance: Deprecated(
    AggregationFieldSchema(MoneyAmountFieldString)
  )
});

export const BasePartySchema = <
  TType extends TSchema,
  TTaxIdentifier extends TSchema
>(
  partyType: TType,
  taxpayerIdentifierType: TTaxIdentifier
) =>
  Type.Intersect([
    BaseEntitySchema,
    Type.Object({
      PartyType: partyType,
      TaxpayerIdentifierType: taxpayerIdentifierType,
      OperationsDepartment: EnumField(mofinOperationsDepartment)
    })
  ]);

const entityPartyFieldMetaSchema = buildFieldMetadata<
  Party,
  TObject<TProperties>
>({
  entityTemplate: getPartyTemplate(PartyType.Entity),
  target: FieldMetaTarget.Schema
});

export const EntityPartyFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Intersect([BasePartyAggregationsSchema, EntityPartyAggregationsSchema])
  ),
  FieldMeta: Type.Partial(entityPartyFieldMetaSchema),
  EntityType: EnumField(mofinEntityType)
});

export const mofinEntityPartySchema = Type.Intersect([
  BasePartySchema(
    LiteralField(PartyType.Entity),
    LiteralField(TaxpayerIdentifierType.EIN)
  ),
  EntityPartyFieldsSchema
]);

const individualFieldMetaSchema = buildFieldMetadata<
  Party,
  TObject<TProperties>
>({
  entityTemplate: getPartyTemplate(PartyType.Individual),
  target: FieldMetaTarget.Schema
});

export const IndividualPartyFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Intersect([
      BasePartyAggregationsSchema,
      IndividualPartyAggregationsSchema
    ])
  ),
  FieldMeta: Type.Partial(individualFieldMetaSchema)
});

export const mofinIndividualPartySchema = Type.Intersect([
  BasePartySchema(
    LiteralField(PartyType.Individual),
    UnionField([
      LiteralField(TaxpayerIdentifierType.SSN),
      LiteralField(TaxpayerIdentifierType.TIN)
    ])
  ),
  IndividualPartyFieldsSchema
]);

export const PartySchema = Type.Union([
  mofinEntityPartySchema,
  mofinIndividualPartySchema
]);

export const mofinIndividualUpdate = OmitUpdateSchema(
  mofinIndividualPartySchema
);
export const mofinEntityUpdate = OmitUpdateSchema(mofinEntityPartySchema);

export const IndividualCreate = OmitCreateSchema(mofinIndividualPartySchema);
export const EntityCreate = OmitCreateSchema(mofinEntityPartySchema);

export const PartyUpdate = Type.Union([
  mofinIndividualUpdate,
  mofinEntityUpdate
]);
export const PartyCreate = Type.Union([IndividualCreate, EntityCreate]);

export const AddChildRequest = Type.Object({
  child: Type.Union([Type.Object({ id: StringField }), PartyCreate])
});

export const PaginateEnhanceRequest = Type.Intersect([
  BasePaginationRequest,
  Type.Object({
    enhance: Type.Optional(
      Type.Object({
        partyTree: Type.Optional(
          Type.Boolean({ default: false, examples: [true, false] })
        )
      })
    )
  })
]);
