import { ElphiEnv } from "../../../common";
import { PartyType } from "../../../entities";
import {
  elphiBaseSchemaEntityParty,
  elphiBaseSchemaIndividualParty
} from "../../base/party.typebox";
import {
  limaEntityPartySchema,
  limaIndividualPartySchema
} from "../../lima/party.typebox";
import {
  mofinEntityPartySchema,
  mofinIndividualPartySchema
} from "../../mofin/party.typebox";
import { EnvToSchemasMap } from "../validationSchemas.types";

export type PartyEnvToSchemaMap = {
  [PartyType.Entity]: EnvToSchemasMap;
  [PartyType.Individual]: EnvToSchemasMap;
};

export const partySchemas: PartyEnvToSchemaMap = {
  [PartyType.Entity]: {
    [ElphiEnv.Localhost]: elphiBaseSchemaEntityParty,
    [ElphiEnv.EdomonTest]: elphiBaseSchemaEntityParty,
    [ElphiEnv.BabarTest]: elphiBaseSchemaEntityParty,
    [ElphiEnv.Demo]: elphiBaseSchemaEntityParty,
    [ElphiEnv.MofinProd]: mofinEntityPartySchema,
    [ElphiEnv.MofinTest]: mofinEntityPartySchema,
    [ElphiEnv.LimaTest]: limaEntityPartySchema,
    [ElphiEnv.LimaPoc]: limaEntityPartySchema,
    [ElphiEnv.LimaProd]: limaEntityPartySchema,
    [ElphiEnv.ElamiaTest]: limaEntityPartySchema
  },
  [PartyType.Individual]: {
    [ElphiEnv.Localhost]: elphiBaseSchemaIndividualParty,
    [ElphiEnv.EdomonTest]: elphiBaseSchemaIndividualParty,
    [ElphiEnv.BabarTest]: elphiBaseSchemaIndividualParty,
    [ElphiEnv.Demo]: elphiBaseSchemaIndividualParty,
    [ElphiEnv.MofinProd]: mofinIndividualPartySchema,
    [ElphiEnv.MofinTest]: mofinIndividualPartySchema,
    [ElphiEnv.LimaTest]: limaIndividualPartySchema,
    [ElphiEnv.LimaPoc]: limaIndividualPartySchema,
    [ElphiEnv.LimaProd]: limaIndividualPartySchema,
    [ElphiEnv.ElamiaTest]: limaIndividualPartySchema
  }
};
