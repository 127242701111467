import { FieldType } from "@elphi/types";
import { MofinPropertyFields } from "@elphi/types/entities/mofin/property";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { booleanOptions } from "../../../../../components/utils/formUtils";
import {
  BaseSchemaPropertyFieldSpecs,
  baseSchemaPropertySpecs
} from "../../../base/spec-files/property/property.fields";
import { integrationSpecs } from "../../../integrations/spec-files/integrationFieldsSpecs";
import { BaseSpec } from "../../../types";
import { mergeSpecs } from "../../../utils/formsSpec.utils";

type MofinSchemaPropertyFieldSpecs = EntityFormFieldSpecs<MofinPropertyFields>;

export type FullMofinSchemaPropertyFieldSpecs =
  Partial<BaseSchemaPropertyFieldSpecs> & MofinSchemaPropertyFieldSpecs;

const mofinPropertyFieldSpecs: BaseSpec<MofinSchemaPropertyFieldSpecs> = {
  aggregations: {},
  PayoffInterestDays: {
    fieldType: FieldType.Number,
    label: "Payoff Interest Days to Add",
    fieldKey: ["PayoffInterestDays"]
  },
  InitialFundedAmount: {
    fieldType: FieldType.Money,
    label: "Initial Funded Amount",
    fieldKey: ["InitialFundedAmount"]
  },
  CanBeRebuiltIndicator: {
    fieldType: FieldType.Boolean,
    label: "Can Be Rebuilt (Legal Nonconforming Only)?",
    fieldKey: ["CanBeRebuiltIndicator"],
    options: booleanOptions
  }
};

const mofinPropertySpecs: FullMofinSchemaPropertyFieldSpecs = mergeSpecs(
  baseSchemaPropertySpecs,
  mofinPropertyFieldSpecs,
  integrationSpecs.property.FloodCertification.ServiceLink.spec
);

export const fullMofinPropertySpecs = createSpecWithFieldMeta({
  spec: mofinPropertySpecs
});
