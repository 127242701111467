import { MofinDeal } from "@elphi/types/entities/mofin/deal";
import { hideFixNFlip } from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import {
  achElectedIndicator,
  condoCertificationFeeAmount,
  contractorReviewFeeAmount,
  creditReportFeeAmount,
  downPaymentAmount,
  feasibilityReviewFeeAmount,
  fundingAgentEmailAddress,
  lenderCounselFeeAmount,
  loanPackageInternationalExecutionIndicator,
  loanPackageNoteTrackingNumber,
  loanPackageTrackingNumber,
  mersMin,
  newYorkProcessingFeeAmount,
  perDiemPrepaidInterestAmount,
  prepaidInterestAmount,
  purchaserWarrantyDeedAddedIndicator,
  sellerHUDAddedIndicator,
  totalOutstandingLoanPayoffAmount,
  totalPurchasePriceAmount
} from "../../base/inputs/deal.inputs";
import { addRulesToInputs, buildSection } from "../../utils/formBuilder.utils";
import {
  achStatus,
  finalTitlePolicyToCustodianDate,
  insuranceMortgageeRequestDate,
  insuranceMortgageeRequestFollowUpDate,
  insuranceMortgageeStatus,
  mERSLoanIndicator,
  payoffPerDiemInterest,
  receivedFinalTitlePolicyIndicator,
  receivedRecordablesIndicator,
  sentRecordablesToCustodianDate,
  warehouseBankStatusType
} from "../inputs/deal.inputs";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";
import { MofinSections } from "../types";

const electronicExecutedLoanPackageSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Electronic Executed Loan Package",
    inputs: [
      loanPackageTrackingNumber,
      loanPackageNoteTrackingNumber,
      loanPackageInternationalExecutionIndicator,
      warehouseBankStatusType,
      achElectedIndicator,
      sellerHUDAddedIndicator,
      purchaserWarrantyDeedAddedIndicator,
      fundingAgentEmailAddress,
      achStatus
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const assignmentSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Assignment",
    inputs: [mERSLoanIndicator, mersMin],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const physicalExecutedLoanPackageSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Physical Executed Loan Package",
    inputs: [
      receivedRecordablesIndicator,
      sentRecordablesToCustodianDate,
      receivedFinalTitlePolicyIndicator,
      finalTitlePolicyToCustodianDate
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const ledgerSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Ledger",
    inputs: [
      totalOutstandingLoanPayoffAmount,
      totalPurchasePriceAmount,
      downPaymentAmount,
      creditReportFeeAmount,
      lenderCounselFeeAmount,
      ...addRulesToInputs({
        inputs: [contractorReviewFeeAmount, feasibilityReviewFeeAmount],
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
      }),
      condoCertificationFeeAmount,
      newYorkProcessingFeeAmount,
      prepaidInterestAmount,
      perDiemPrepaidInterestAmount,
      payoffPerDiemInterest
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const secondaryMarketSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Secondary Market",
    inputs: [
      insuranceMortgageeStatus,
      insuranceMortgageeRequestDate,
      insuranceMortgageeRequestFollowUpDate
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });
export const dealSectionBuilders: MofinSections["deal"] = {
  electronicExecutedLoanPackageSection,
  physicalExecutedLoanPackageSection,
  assignmentSection,
  ledgerSection,
  secondaryMarketSection
};
