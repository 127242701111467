import { BaseEntity } from "../../db";

export enum ElphiFieldsTemplateType {
  POS_LOAN_PAGE = "posLoanPage"
}

export type FieldsTemplateSelection = {
  [key: string]: {
    order: number;
    label?: string;
  };
};

export type ElphiFieldsTemplate = BaseEntity<{
  templateName: string;
  templateType: ElphiFieldsTemplateType;
  fields: FieldsTemplateSelection;
}>;
