import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text, TextProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type OptionProps = {
  titleContainer?: TextProps;
  containerColor?: string;
  containerBorderColor?: string;
  childrenContainerColor?: string;
  startingHeight?: number | string;
  endingHeight?: number | string;
  withScroll?: boolean;
  mb?: string;
  collapseIcon?: JSX.Element;
  expandIcon?: JSX.Element;
  rightHeaderIcon?: JSX.Element;
  shouldShow?: boolean;
};

export type CustomCollapseProps = {
  children?: React.ReactNode;
  customBody?: React.ReactNode;
  collapsedBody?: React.ReactNode;
  title?: string;
  rightContent?: string;
  options?: OptionProps;
};

export const CustomCollapse = ({
  children,
  customBody,
  collapsedBody,
  title,
  options,
  rightContent
}: CustomCollapseProps) => {
  const {
    containerColor = "gray.100",
    containerBorderColor,
    endingHeight,
    startingHeight,
    withScroll,
    mb,
    collapseIcon,
    expandIcon,
    rightHeaderIcon,
    titleContainer,
    shouldShow,
    childrenContainerColor = "white"
  } = options || {};

  const [show, setShow] = useState(shouldShow);

  useEffect(() => {
    setShow(shouldShow);
  }, [shouldShow]);

  return (
    <Flex mb={mb}>
      <Box
        padding={"6px"}
        borderRadius={"8px"}
        bgColor={containerColor}
        borderColor={containerBorderColor}
        borderWidth={containerBorderColor ? "1px" : undefined}
        w="100%"
      >
        <Flex
          cursor={"pointer"}
          onClick={() => setShow(!show)}
          justify={"space-between"}
          alignItems={"center"}
          mb={"6px"}
        >
          {title && (
            <Flex>
              <Text fontSize={"16px"} {...titleContainer}>
                {title}
              </Text>
            </Flex>
          )}
          <Flex alignItems={"center"}>
            {rightContent && (
              <Flex mr={"6px"}>
                <Text fontSize={"12px"}>{rightContent}</Text>
              </Flex>
            )}
            <Box>
              {show
                ? collapseIcon || <ChevronUpIcon w={"24px"} h={"24px"} />
                : expandIcon || <ChevronDownIcon w={"24px"} h={"24px"} />}
              {rightHeaderIcon}
            </Box>
          </Flex>
        </Flex>
        <Collapse
          in={show}
          startingHeight={startingHeight}
          endingHeight={endingHeight}
          style={withScroll ? { overflowY: "auto" } : {}}
        >
          {children && (
            <Flex
              flexDir={"column"}
              justify={"space-between"}
              padding={"6px"}
              borderRadius={"8px"}
              bgColor={childrenContainerColor}
              mb={"12px"}
            >
              {children}
            </Flex>
          )}
          {!!customBody && customBody}
        </Collapse>
        <Collapse
          in={!show && !!collapsedBody}
          startingHeight={startingHeight}
          endingHeight={endingHeight}
          style={withScroll ? { overflowY: "auto" } : {}}
        >
          {!!collapsedBody && collapsedBody}
        </Collapse>
      </Box>
    </Flex>
  );
};
