export enum LoanpassFieldType {
  String = "string",
  Number = "number",
  Enum = "enum",
  Duration = "duration",
  Array = "array"
}

export type ValueTypeObject = {
  fieldId: string;
  value: {
    type: LoanpassFieldType;
    value: unknown;
  };
};

export type EnumObject = {
  fieldId: string;
  value: {
    type: LoanpassFieldType;
    enumTypeId: string;
    variantId: unknown;
  };
};

export type DurationObject = {
  fieldId: string;
  value: {
    type: LoanpassFieldType;
    count: string;
    unit: "days";
  };
};

export type LoanpassFieldValue = ValueTypeObject | EnumObject | DurationObject;

export type LoanpassPriceAdjustment = {
  ruleId: string;
  amount: string;
  description: string;
};

export type LoanpassScenario = {
  pipelineRecordId: string;
  id: string;
  pricingProfileId: string;
  name: string;
  createdAt: string;
  preferred: boolean;
  creditApplicationFieldValues: LoanpassFieldValue[];
  selectedProductId: string;
};

export type LoanpassStipulations = {
  ruleId: string;
  text: string;
};

export type LoanpassMessageType<
  T extends "float-request" | "lock-ledger-updated"
> = {
  message: T;
};
