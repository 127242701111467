import { Flex } from "@chakra-ui/react";
import { LoanPassPricingAggregationIntergrationFields } from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { DevFeatureFlag } from "@elphi/utils";
import { get } from "lodash";
import { useState } from "react";
import { NOT_AVAILABLE } from "../../../constants/common";
import { GateKeeper } from "../../../features/gate/GateKeeper";
import { auth } from "../../../firebase/firebaseConfig";
import { AggregationsIntegrations } from "../../../forms/schemas/integrations/types/integrations.types";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import useDealHooks from "../../../hooks/deal.hooks";
import { LoanpassModal } from "./modals";
import { InMappingModalBody } from "./modals/in-mapping/InMapping";
import { LoanPassPricingIframe } from "./pricingEngine/LoanPassPricing";
import {
  useLoanpassHooks,
  useLoanpassWorkerHooks
} from "./pricingEngine/loanpass.hooks";
import { LoanpassSidebar } from "./sidebar/LoanpassSidebar";

const productNamePath: DotNestedKeys<
  AggregationsIntegrations<LoanPassPricingAggregationIntergrationFields>
> = "aggregations.Integrations.Pricing.Loanpass.productName.thirdParty";
export const LoanPassPricingPage = () => {
  const {
    getLoanpassPipelineRecoreds,
    mapElphiToLoanpass,
    inMapping,
    removeMapping,
    updateDealWithMapping,
    updateDealWithMappingResponse,
    selectedPipelineRecord
  } = useLoanpassHooks();
  const { selectedDeal } = useDealHooks();

  const {
    passMessageToWorker,
    isListening,
    setIframeRef,
    sendDataFromElphiToLoanpass
  } = useLoanpassWorkerHooks();
  const [isReadyToShowLoanpass, setIsReadyToShowLoanpass] = useState(false);

  useAuthStateChangeHook({
    onAuthStateChange: async (user) => {
      if (user) {
        getLoanpassPipelineRecoreds();
      }
    },
    deps: [auth.currentUser]
  });

  const handleSubmit = async () => {
    const mappingResponse = await mapElphiToLoanpass();
    if (mappingResponse) {
      sendDataFromElphiToLoanpass(mappingResponse);
      setIsReadyToShowLoanpass(true);
    }
  };

  const modalHeader = get(inMapping?.deal, productNamePath) || NOT_AVAILABLE;
  return (
    <GateKeeper gate={DevFeatureFlag.ESD_3283_LoanPASS_integration}>
      <Flex
        pl={4}
        gap={8}
        w="100%"
        h={"100%"}
        overflow={"scroll"}
        position={"relative"}
      >
        <LoanpassSidebar
          isIframeListening={isListening}
          selectedPipelineRecord={selectedPipelineRecord?.id}
          onConfirm={handleSubmit}
        />
        <LoanPassPricingIframe
          shouldHide={!isReadyToShowLoanpass}
          onIframeLoad={setIframeRef}
          handleMessage={passMessageToWorker}
        />
        {!isReadyToShowLoanpass && (
          <Flex
            position={"absolute"}
            top={"50%"}
            left={"45%"}
            fontSize={"2xl"}
            fontWeight={"bold"}
            justifyContent="center"
            alignItems="center"
          >
            Review and submit your loan info to see available products
          </Flex>
        )}
      </Flex>
      {inMapping && selectedDeal && (
        <LoanpassModal
          maxWidth={1000}
          isOpen={!!inMapping}
          modalTitle={`Confirm Loan Product - ${modalHeader}`}
          modalBody={
            <InMappingModalBody
              messageType={inMapping.messageType}
              mappedDeal={inMapping.deal}
              selectedDeal={selectedDeal}
            />
          }
          onClose={removeMapping}
          handleConfirm={updateDealWithMapping}
          isLoading={updateDealWithMappingResponse.isLoading}
        />
      )}
    </GateKeeper>
  );
};
