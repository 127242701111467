import {
  booleanValidation,
  moneyValidation,
  numberValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";

export const payoffInterestDays = buildInput({
  fieldKey: mofinSpecs.property?.entitySpecs?.PayoffInterestDays?.fieldKey,
  validation: numberValidation
});

export const initialFundedAmount = buildInput({
  fieldKey: mofinSpecs.property?.entitySpecs?.InitialFundedAmount?.fieldKey,
  validation: moneyValidation
});
export const canBeRebuiltIndicator = buildInput({
  fieldKey: mofinSpecs.property?.entitySpecs?.CanBeRebuiltIndicator?.fieldKey,
  validation: booleanValidation
});
