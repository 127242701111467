import {
  FloatRequestEvent,
  LoanpassFieldValue,
  LockLedgerUpdated
} from "@elphi/types";

export enum LoanpassMessageType {
  PrepareLogin = "prepare-login",
  Connect = "connect",
  LogIn = "log-in",
  LogOut = "log-out",
  EnablePriceLocking = "enable-price-locking",
  EnableFloatRequests = "enable-float-requests",
  SetFields = "set-fields",
  CreatePipelineRecord = "create-pipeline-record",
  SetPipelineRecord = "set-pipeline-record-id",
  Listening = "listening",
  LoggedIn = "logged-in",
  LogInError = "log-in-error",
  PriceLock = "price-lock",
  FloatRequest = "float-request",
  LockLedgerUpdated = "lock-ledger-updated",
  LockLedgerUpdateError = "lock-ledger-update-error"
}

type LoanpassPayload = { loanpassPayload: LoanpassFieldValue[] };
type LoampassMessagePayload<T extends LoanpassMessageType> = {
  messageType: T;
};

type LoampassErrorMessage = LoampassMessagePayload<
  LoanpassMessageType.LogInError | LoanpassMessageType.LockLedgerUpdateError
> & {
  error: string;
};
type LoampassListeningMessage =
  LoampassMessagePayload<LoanpassMessageType.Listening>;
type LoampassLogInMessage =
  LoampassMessagePayload<LoanpassMessageType.LogIn> & {
    clientAccessId: string;
    email: string;
    password: string;
    origin: string;
  };
type LoampassLoggedInMessage =
  LoampassMessagePayload<LoanpassMessageType.LoggedIn>;
type LoampassLogOutMessage = LoampassMessagePayload<LoanpassMessageType.LogOut>;
type LoampassLockLedgerUpdatedMessage =
  LoampassMessagePayload<LoanpassMessageType.LockLedgerUpdated> &
    LockLedgerUpdated;
type LoampassFloatRequestMessage =
  LoampassMessagePayload<LoanpassMessageType.FloatRequest> & FloatRequestEvent;

type SetFieldsMessage = LoampassMessagePayload<LoanpassMessageType.SetFields> &
  LoanpassPayload;

type SetPipelineRecordMessage =
  LoampassMessagePayload<LoanpassMessageType.SetPipelineRecord> &
    LoanpassPayload;

type PriceLockMessage = LoampassMessagePayload<LoanpassMessageType.PriceLock>;

export type LoanpassMessage =
  | LoampassListeningMessage
  | LoampassLoggedInMessage
  | LoampassLogInMessage
  | LoampassLogOutMessage
  | LoampassLockLedgerUpdatedMessage
  | LoampassFloatRequestMessage
  | LoampassErrorMessage
  | SetFieldsMessage
  | SetPipelineRecordMessage
  | PriceLockMessage;
