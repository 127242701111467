import { BaseEntity } from "../../db";
import { ElphiEntityType } from "../elphiEntities.types";

export enum ElphiFieldMetadataType {
  Number = "number",
  String = "string",
  Aggregation = "aggregation",
  Array = "array"
}

export enum ElphiFieldMetadataStatus {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export type ElphiFieldMetadata = BaseEntity<{
  originalKey: string;
  camelCaseKey: string;
  label: string;
  type: ElphiFieldMetadataType;
  entityType: ElphiEntityType;
  status: ElphiFieldMetadataStatus;
}>;
