import {
  FieldType,
  IntegrationType,
  LoanPassPricingAggregationIntergrationFields,
  PricingVendorType
} from "@elphi/types";
import { booleanOptions } from "../../../../../../components/utils/formUtils";
import { IntegrationsFieldSpecs } from "../../../types/integrations.types";

export type PricingIntegrationFieldSpecs = IntegrationsFieldSpecs<
  LoanPassPricingAggregationIntergrationFields,
  {}
>;

export const pricingIntegrationFieldSpecs: PricingIntegrationFieldSpecs = {
  aggregations: {
    Integrations: {
      [IntegrationType.Pricing]: {
        [PricingVendorType.Loanpass]: {
          adjustedPrice: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "adjustedPrice",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "adjustedPrice",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Adjusted Price"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "adjustedPrice",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Adjusted Price"
            }
          },
          adjustedRate: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "adjustedRate",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "adjustedRate",
                "override"
              ],
              fieldType: FieldType.Number,
              label: "Adjusted Rate"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "adjustedRate",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Adjusted Rate"
            }
          },
          appraisedValue: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "appraisedValue",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "appraisedValue",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Appraised Value"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "appraisedValue",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Appraised Value"
            }
          },
          baseLoanAmount: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "baseLoanAmount",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "baseLoanAmount",
                "override"
              ],
              fieldType: FieldType.Money,
              label: "Base Loan Amount"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "baseLoanAmount",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Base Loan Amount"
            }
          },
          citizenshipType: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "citizenshipType",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "citizenshipType",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Citizenship Type"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "citizenshipType",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Citizenship Type"
            }
          },
          decisionCreditScore: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "decisionCreditScore",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "decisionCreditScore",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Decision Credit Score"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "decisionCreditScore",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Decision Credit Score"
            }
          },
          expirationDate: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "expirationDate",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "expirationDate",
                "override"
              ],
              fieldType: FieldType.Date,
              label: "Expiration Date"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "expirationDate",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Expiration Date"
            }
          },
          investorName: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "investorName",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "investorName",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Investor Name"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "investorName",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Investor Name"
            }
          },
          loanPurpose: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "loanPurpose",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "loanPurpose",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Loan Purpose"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "loanPurpose",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Loan Purpose"
            }
          },
          lockPeriod: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "lockPeriod",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "lockPeriod",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Lock Period"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "lockPeriod",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Lock Period"
            }
          },
          lockStatus: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "lockStatus",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "lockStatus",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Lock Status"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "lockStatus",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Lock Status"
            }
          },
          mortgageLatePayments: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "mortgageLatePayments",
                "focused"
              ],
              fieldType: FieldType.Boolean,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "mortgageLatePayments",
                "override"
              ],
              fieldType: FieldType.Boolean,
              label: "Mortgage Late Payments",
              options: booleanOptions
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "mortgageLatePayments",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Mortgage Late Payments"
            }
          },
          pipelineRecordId: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "pipelineRecordId",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "pipelineRecordId",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Pipeline Record ID"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "pipelineRecordId",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Pipeline Record ID"
            }
          },
          pipelineRecordName: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "pipelineRecordName",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "pipelineRecordName",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Pipeline Record Name"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "pipelineRecordName",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Pipeline Record Name"
            }
          },
          prepaymentPenaltyTerm: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "prepaymentPenaltyTerm",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "prepaymentPenaltyTerm",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Prepayment Penalty Term"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "prepaymentPenaltyTerm",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Prepayment Penalty Term"
            }
          },
          priceAdjustments: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "priceAdjustments",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "priceAdjustments",
                "override"
              ],
              fieldType: FieldType.RichText,
              label: "Price Adjustments"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "priceAdjustments",
                "thirdParty"
              ],
              fieldType: FieldType.RichText,
              label: "Price Adjustments"
            }
          },
          productName: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "productName",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "productName",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Product Name"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "productName",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Product Name"
            }
          },
          propertyType: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "propertyType",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "propertyType",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Property Type"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "propertyType",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Property Type"
            }
          },
          requestDate: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "requestDate",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "requestDate",
                "override"
              ],
              fieldType: FieldType.Date,
              label: "Request Date"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "requestDate",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Request Date"
            }
          },
          stateCode: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "stateCode",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "stateCode",
                "override"
              ],
              fieldType: FieldType.String,
              label: "State Code"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "stateCode",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "State Code"
            }
          },
          stipulations: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "stipulations",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "stipulations",
                "override"
              ],
              fieldType: FieldType.RichText,
              label: "Stipulations"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "stipulations",
                "thirdParty"
              ],
              fieldType: FieldType.RichText,
              label: "Stipulations"
            }
          },
          totalLLPA: {
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "totalLLPA",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "totalLLPA",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Total LLPA"
            },
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.Pricing,
                PricingVendorType.Loanpass,
                "totalLLPA",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Total LLPA"
            }
          }
        }
      }
    }
  },
  Integrations: {}
};
