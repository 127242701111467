import { MofinIndividualParty } from "@elphi/types/entities/mofin/party";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";
import {
  backgroundReportDate,
  backgroundReportExpirationDate,
  citizenshipResidency,
  creditReportNotes,
  creditScore,
  individualBirthDate,
  individualCitizenshipCountry,
  individualContactPointRoleType,
  individualFirstName,
  individualGovernmentIssuedIDExpirationDate,
  individualLastName,
  individualMiddleName,
  individualUSCitizenshipIndicator,
  loanAmountInProcess,
  locExpirationDateAggregation,
  locStatusType,
  operationsDepartment,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  totalApprovedLOC,
  totalStatementQualifyingBalance
} from "../../../base/inputs/party.inputs";
import { buildSection } from "../../../utils/formBuilder.utils";
import { mortgageLatePaymentsIndicator } from "../../inputs/party.inputs";
import { mofinSpecs } from "../../spec-files/mofinFieldSpecs";
import { MofinIndividualPartySections } from "../../types";

const individualInfoSection = (r: {
  state: Partial<MofinIndividualParty>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Individual Info",
    inputs: [
      individualFirstName,
      individualMiddleName,
      individualLastName,
      individualBirthDate,
      individualGovernmentIssuedIDExpirationDate,
      backgroundReportDate,
      backgroundReportExpirationDate,
      individualCitizenshipCountry,
      individualUSCitizenshipIndicator,
      individualContactPointRoleType,
      partyContactPointTelephoneValue,
      partyContactPointEmailValue,
      operationsDepartment,
      totalStatementQualifyingBalance,
      creditReportNotes,
      locStatusType,
      totalApprovedLOC,
      loanAmountInProcess,
      locExpirationDateAggregation,
      citizenshipResidency,
      creditScore,
      mortgageLatePaymentsIndicator
    ],
    fieldSpecs: mofinSpecs.party.entitySpecs
  });

export const individualPartySectionBuilders: MofinIndividualPartySections = {
  individualInfoSection
};
